@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


.website-layer {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.website-layer .website-v-layer {
  background-color: #ffffff;
  /* height: 8197px; */
  width: 100%;
}

.website-layer .main-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.website-layer .hero-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-image: url('./assests/hero-bg.svg'); /* Set the image */
  background-size: cover; /* Make the image cover the entire element */
  height: 88vh; /* Make the background fill the entire viewport height */
  margin: 0; /* Remove any default margin */
  max-height: 88vh;
}


.website-layer .navbar {
  align-items: center;
  background-color: #0f1a1b;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #008b61;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 28px 10vw;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 13vh;
  max-height: 13vh;
}

.website-layer .brand-logo-lt {
  background-image: url(./assests/brand-logo-lt.svg);
  background-size: 100% 100%;
  height: inherit;
  position: relative;
  width: 12vw;
}

.website-layer .navlinks {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2vw;
  position: relative;
}

.website-layer .links {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.10vw;
  font-weight: 400;
  height: 25px;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  /* width: 98px; */
  cursor: pointer;
}

.website-layer .links:hover {
 /* text-decoration: underline; */
 color: var(--primary)
}

.website-layer .nav-btn {
  align-items: center;
  background-color: #0f1a1b;
  border: 1px solid;
  border-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 1vw 2vw;
  position: relative;
  cursor: pointer;
}

.website-layer .download-btn {
  align-items: center;
  background-color: #0f1a1b;
  border: 1px solid;
  border-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 1.5vw 3vw;
  position: relative;
  cursor: pointer;
}

.website-layer .text-wrapper-4 {
  color: var(--primary);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25vw;
  font-weight: 400;
  letter-spacing: 0.60px;
  /* line-height: 25.8px; */
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .hero-section-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  /* height: 701px; */
  justify-content: center;
  /* padding: 80px 180px; */
  position: relative;
  width: 100%;
  /* height: 100vh; */
  margin: auto;
  /* width: 1278px; */
}

.website-layer .hero-header {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.website-layer .defend-against-cyber {
  color: transparent;
  font-family: var(--h1-style-font-family);
  font-size: 3.25vw;
  font-style: var(--h1-style-font-style);
  font-weight: var(--h1-style-font-weight);
  letter-spacing: var(--h1-style-letter-spacing);
  line-height: var(--h1-style-line-height);
  position: relative;
  text-align: center;
  width: 50vw;
  margin: 0px !important;

}

.website-layer .span {
  color: #f4fffb;
  font-family: var(--h1-style-font-family);
  font-size: inherit;
  font-style: var(--h1-style-font-style);
  font-weight: var(--h1-style-font-weight);
  letter-spacing: var(--h1-style-letter-spacing);
  line-height: var(--h1-style-line-height);
}

.website-layer .text-wrapper-5 {
  color: #00ffb2;
  font-family: var(--h1-style-font-family);
  font-size: inherit;
  font-style: var(--h1-style-font-style);
  font-weight: var(--h1-style-font-weight);
  letter-spacing: var(--h1-style-letter-spacing);
  line-height: var(--h1-style-line-height);
}

.website-layer .p {
  color: #f4fffbcc;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 55vw;
  margin: 10px 0px 0px 0px  !important;
  padding: 0px !important
}

.website-layer .div-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 1.5vw 3vw;
  position: relative;
  margin-left: 2vw;
  cursor: pointer;
}

.website-layer .section-div-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 25px 50px;
  position: relative;
  margin-left: 30px;
}

.website-layer .text-wrapper-6 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25vw;
  font-weight: 400;
  letter-spacing: 0.60px;
  /* line-height: 25.8px; */
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .text-wrapper-section {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .trust {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4vw;
  /* margin-left: -64.50px; */
  /* margin-right: -64.50px; */
  padding: 6vw 0px 0px;
  position: relative;
}

.website-layer .overall-rating {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 16px; */
  justify-content: flex-end;
  position: relative;
}

.website-layer .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  /* gap: 20px; */
  position: relative;
}

.website-layer .IMG {
  height: 39px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 41px;
}

.website-layer .img {
  flex: 0 0 auto;
  position: relative;
  width: 15vw;
}

.website-layer .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
  width: 60vw;
  overflow: hidden;
}

.website-layer .image-slider {
  width: 40vw;
  display: flex;
  gap: 5vw;
  animation: slide 20s linear infinite; /* Slide animation */
}

.website-layer .img-2 {
  object-fit: scale-down;
  position: relative;
  height: 6vh;
  max-width: 7vw;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%); /* Move the entire slider to the left */
  }
}

.website-layer .services-section {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3vw;
  justify-content: center;
  padding: 3vw 0vw;
  position: relative;
  width: 81vw;
  box-sizing: border-box;
}

.website-layer .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2vw;
  position: relative;
}

.website-layer .primary-btn-instance {
  background-color: #c3f8e8 !important;
  display: inline-flex !important;
  flex: 0 0 auto !important;
  height: unset !important;
  padding: 8px 16px !important;
  width: unset !important;
  border: none;
  border-radius: 10px;
  color: "#006c4c";
  font-size: 1vw;
}

.website-layer .design-component-instance-node {
  color: #006c4b !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.42px !important;
  line-height: 18.1px !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: -1.00px !important;
}

.website-layer .title {
  margin-right: -2.00px;
  position: relative;
  width: 50vw;
}



.website-layer .line {
  position: relative;
  width: 10vw;
  /* height: 7px;
  left: 273px;
  position: absolute;
  top: 75px;
  width: 175px; */
}

.website-layer .text-wrapper-8 {
  color: #0f1a1b;
  font-family: var(--h2-style-font-family);
  font-size: 2vw;
  font-style: var(--h2-style-font-style);
  font-weight: var(--h2-style-font-weight);
  left: 0;
  letter-spacing: var(--h2-style-letter-spacing);
  line-height: var(--h2-style-line-height);
  position: relative;
  top: 0;
}

.website-layer .services-card {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3vw;
  justify-content: center;
  margin-right: -1.00px;
  position: relative;
}

.website-layer .small-cards {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px 10px; */
  justify-content: space-around;
  position: relative;
  /* width: 85vw; */
}

.website-layer .wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1.5vw;
  position: relative;
  justify-content: space-between;
  width: 53vw;
  height: 22vw;
}

.website-layer .card {
  align-items: flex-start;
  background-color: #d3d3d3;
  border: 1px solid;
  border-color: #03000033;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 1vw 0.75vw;
  position: relative;
  width: 25vw;
  box-sizing: border-box;
  height: -webkit-fill-available;
}

.website-layer .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2vw;
  position: relative;
  width: 100%;
}

.website-layer .frame-4 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 12px; */
  /* margin-right: -22.00px; */
  /* padding: 0px 9px; */
  position: relative;
  /* width: 320px; */
}

.website-layer .cardHeader {
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5vw;
  font-weight: 400;
  letter-spacing: 0.84px;
  /* line-height: 36.1px; */
  position: relative;
}

.website-layer .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.2vw;
  position: relative;
  width: 100%;
}

.website-layer .frame-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1vw;
  position: relative;
  width: 100%;
}

.website-layer .group-wrapper {
  height: 17px;
  position: relative;
  width: 20px;
}

.website-layer .group-2 {
  height: 17px;
}

.website-layer .overlap-group-3 {
  height: 18px;
  position: relative;
  top: -1px;
  width: 21px;
}

.website-layer .ellipse {
  background-color: #030000;
  border-radius: 8.5px;
  height: 1vw;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 1px;
  width: 1vw;
}

.website-layer .vector {
  height: 1vw;
  left: 3px;
  position: absolute;
  top: 0;
  width: 1vw;
}

.website-layer .cardPointer {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2vw;
  font-weight: 400;
  letter-spacing: 0.48px;
  /* line-height: 20.6px; */
  margin-top: -1.00px;
  position: relative;
}

.website-layer .card-1 {
  align-items: flex-start;
  background-color: #e0fcff;
  border: 1px solid;
  border-color: #021f2233;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* height: 30vh; */
  padding: 1vw 0.75vw;
  position: relative;
  width: 25vw;
  box-sizing: border-box;
  height: -webkit-fill-available;
}

.website-layer .frame-7 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  margin-right: -23.00px;
  padding: 0px 9px;
  position: relative;
  width: 320px;
}


.website-layer .text-wrapper-11 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.website-layer .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 11px;
  position: relative;
}

.website-layer .card-2 {
  align-items: flex-start;
  background-color: #ffe2e2;
  border: 1px solid;
  border-color: #18010133;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* height: 30vh; */
  padding: 1vw 0.75vw;
  position: relative;
  width: 25vw;
  box-sizing: border-box;
  height: -webkit-fill-available;
}


.website-layer .card-3 {
  align-items: flex-start;
  background-color: #cff5da;
  border: 1px solid;
  border-color: #015e1c33;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* height: 30vh; */
  padding: 1vw 0.75vw;
  position: relative;
  width: 25vw;
  box-sizing: border-box;
  height: -webkit-fill-available;
}

.website-layer .card-big {
  align-items: flex-start;
  background-color: rgba(245, 255, 252, 1);
  border: 1px solid;
  border-color: #015e1c33;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* height: 69vh; */
  padding: 1vw 0.75vw;
  position: relative;
  width: 25vw;
  box-sizing: border-box;
  height: -webkit-fill-available;
}


.website-layer .overlap-group-wrapper {
  height: 17px;
  width: 20px;
}

.website-layer .big-card {
  align-self: stretch;
  position: relative;
  width: 25vw;
  height: 45.5vw;
}

.website-layer .report-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 90px 12vw;
  position: relative;
  width: 100vw;
  background-color: #0f1a1b;
  box-sizing: border-box;

}

.website-layer .report-component {
  align-items: center;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  padding: 1vw 4vw;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
}

.website-layer .section-left {
  padding: 40px;
    border: 1px solid var(--variable-collection-primary-dk);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
}

.website-layer .section-right{
  padding: 40px;
    border: 1px solid var(--variable-collection-primary-dk);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
}
.website-layer .content-left {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 49px;
  position: relative;
  width: 30vw;
  margin-top: 50px;
  justify-content: center;
}

.website-layer .content-right {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 30vw;
  margin-top: 50px;
  justify-content: center;
  text-align: center;
}

.website-layer .frame-9 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 86px;
  padding: 0px 9px;
  position: relative;
  width: 320px;
}

.website-layer .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  margin-right: -9.00px;
  position: relative;
}

.website-layer .text-wrapper-13 {
  color: var(--primary-light);
  font-family: var(--h2-small-font-family);
  font-size: var(--h2-small-font-size);
  font-style: var(--h2-small-font-style);
  font-weight: var(--h2-small-font-weight);
  letter-spacing: var(--h2-small-letter-spacing);
  line-height: var(--h2-small-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 282px;
}

.website-layer .text-wrapper-131 {
  color: var(--primary-light);
  font-family: var(--h2-small-font-family);
  font-size: var(--h2-small-font-size);
  font-style: var(--h2-small-font-style);
  font-weight: var(--h2-small-font-weight);
  letter-spacing: var(--h2-small-letter-spacing);
  line-height: var(--h2-small-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 282px;
}

.website-layer .comprehensive {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  opacity: 0.8;
  position: relative;
  text-align: center;
  width: 30vw;
  margin-bottom: 50px;
}

.website-layer .primary-btn-2 {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0.48px !important;
  line-height: 20.6px !important;
}

.website-layer .primary-btn-3 {
  flex: 0 0 auto !important;
}

.website-layer .report-img {
  margin-bottom: -11.99px;
  margin-right: -11.99px;
  margin-top: -11.98px;
  position: relative;
  width: 25vw;
}

.website-layer .stats-section {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 72px;
  padding: 90px 72px;
  position: relative;
  width: 85vw;
  box-sizing: border-box;
}

.website-layer .div-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 75vw;
}

.website-layer .header {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 412px;
  justify-content: space-between;
  position: relative;
  width: 39vw;
}

.website-layer .div-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 36px;
  position: relative;
}

.website-layer .group-3 {
  height: 138px;
  margin-right: -2.00px;
  position: relative;
  width: 535px;
}

.website-layer .overlap-group-4 {
  height: 138px;
  position: relative;
  width: 533px;
}

.website-layer .line-2 {
  height: 7px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 407px;
}

.website-layer .all-in-one-cyber {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  opacity: 0.8;
  position: relative;
  width: 533px;
}

.website-layer .frame-11 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 533px;
}

.website-layer .text-wrapper-14 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.website-layer .lines-of-code {
  align-self: stretch;
  color: #0f1a1b;
  font-family: var(--h3-style-font-family);
  font-size: var(--h3-style-font-size);
  font-style: var(--h3-style-font-style);
  font-weight: var(--h3-style-font-weight);
  letter-spacing: var(--h3-style-letter-spacing);
  line-height: var(--h3-style-line-height);
  position: relative;
}

.website-layer .frame-12 {
  background-image: url(./assests/frame-313.png); 
  background-size: 100% 100%;
  height: 412px;
  position: relative;
  width: 563px;
}

.website-layer .overlap-wrapper {
  height: 102px;
  left: 138px;
  position: absolute;
  top: 113px;
  transform: rotate(-90deg);
  width: 123px;
}

.website-layer .overlap {
  height: 102px;
  position: relative;
}

.website-layer .group-4 {
  height: 102px;
  left: 0;
  position: absolute;
  top: 0;
  width: 123px;
}

.website-layer .overlap-2 {
  height: 102px;
  position: relative;
  width: 115px;
}

.website-layer .text-wrapper-15 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 6px;
  position: absolute;
  text-align: right;
  top: 9px;
  transform: rotate(45deg);
  width: 28px;
}

.website-layer .text-wrapper-16 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 6px;
  position: absolute;
  text-align: right;
  top: 32px;
  transform: rotate(45deg);
  width: 28px;
}

.website-layer .overlap-group-5 {
  height: 86px;
  left: 9px;
  position: absolute;
  top: 14px;
  width: 38px;
}

.website-layer .text-wrapper-17 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: -4px;
  letter-spacing: 0;
  line-height: 6px;
  position: absolute;
  text-align: right;
  top: 41px;
  transform: rotate(45deg);
  width: 42px;
}

.website-layer .line-3 {
  height: 1px;
  left: -5px;
  position: absolute;
  top: 42px;
  transform: rotate(90deg);
  width: 86px;
}

.website-layer .text-wrapper-18 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: -5px;
  letter-spacing: 0;
  line-height: 6px;
  position: absolute;
  text-align: right;
  top: 77px;
  transform: rotate(45deg);
  width: 52px;
}

.website-layer .rectangle {
  background-color: var(--primary);
  height: 11px;
  left: 46px;
  position: absolute;
  top: 19px;
  width: 16px;
}

.website-layer .rectangle-2 {
  background-color: var(--primary);
  height: 12px;
  left: 46px;
  position: absolute;
  top: 42px;
  width: 31px;
}

.website-layer .rectangle-3 {
  background-color: var(--primary);
  height: 12px;
  left: 46px;
  position: absolute;
  top: 64px;
  width: 49px;
}

.website-layer .rectangle-4 {
  background-color: #00ffb2;
  height: 12px;
  left: 46px;
  position: absolute;
  top: 88px;
  width: 69px;
}

.website-layer .text-wrapper-19 {
  color: #0f1a1b;
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 50px;
  letter-spacing: 0;
  line-height: 6.0px;
  position: absolute;
  text-align: right;
  top: 18px;
  transform: rotate(90deg);
  width: 14px;
}

.website-layer .text-wrapper-20 {
  color: #0f1a1b;
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0;
  line-height: 6.0px;
  position: absolute;
  text-align: right;
  top: 41px;
  transform: rotate(90deg);
  width: 14px;
}

.website-layer .text-wrapper-21 {
  color: #0f1a1b;
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 82px;
  letter-spacing: 0;
  line-height: 6.0px;
  position: absolute;
  text-align: right;
  top: 64px;
  transform: rotate(90deg);
  width: 14px;
}

.website-layer .text-wrapper-22 {
  color: #0f1a1b;
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 101px;
  letter-spacing: 0;
  line-height: 6.0px;
  position: absolute;
  text-align: right;
  top: 87px;
  transform: rotate(90deg);
  width: 14px;
}

.website-layer .group-5 {
  height: 25px;
  left: 46px;
  position: absolute;
  top: 184px;
  width: 62px;
}

.website-layer .overlap-3 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 63px;
}

.website-layer .group-6 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.website-layer .rectangle-5 {
  background-color: #2d146f;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .text-wrapper-23 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 24px;
}

.website-layer .group-7 {
  height: 4px;
  left: 28px;
  position: absolute;
  top: 0;
  width: 35px;
}

.website-layer .rectangle-6 {
  background-color: #8b5fe5;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .text-wrapper-24 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 26px;
}

.website-layer .group-8 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 64px;
}

.website-layer .rectangle-7 {
  background-color: #1eebad;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .text-wrapper-25 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 56px;
}

.website-layer .group-9 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 56px;
}

.website-layer .rectangle-8 {
  background-color: #489edb;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .text-wrapper-26 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 47px;
}

.website-layer .overlap-4 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 21px;
  width: 60px;
}

.website-layer .group-10 {
  height: 4px;
  left: 28px;
  position: absolute;
  top: 0;
  width: 32px;
}

.website-layer .rectangle-9 {
  background-color: #9becd4;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .group-11 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 29px;
}

.website-layer .rectangle-10 {
  background-color: #2c009e;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .text-wrapper-27 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 21px;
}

.website-layer .group-12 {
  height: 87px;
  left: 203px;
  position: absolute;
  top: 288px;
  width: 326px;
}

.website-layer .frame-13 {
  align-items: center;
  display: flex;
  height: 8px;
  justify-content: space-between;
  left: 15px;
  position: absolute;
  top: 79px;
  width: 311px;
}

.website-layer .text-wrapper-28 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 5.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 5.8px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .frame-14 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 76px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 11px;
}

.website-layer .text-wrapper-29 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 5.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 5.8px;
  margin-top: -0.64px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .line-4 {
  height: 1px;
  left: 15px;
  position: absolute;
  top: 74px;
  width: 311px;
}

.website-layer .overlap-5 {
  height: 52px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 310px;
}

.website-layer .vector-2 {
  height: 52px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 309px;
}

.website-layer .vector-3 {
  height: 36px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 308px;
}

.website-layer .vector-4 {
  height: 29px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 308px;
}

.website-layer .vector-5 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 308px;
}

.website-layer .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.website-layer .text-wrapper-30 {
  color: #0f1a1b;
  font-family: var(--h3-style-font-family);
  font-size: var(--h3-style-font-size);
  font-style: var(--h3-style-font-style);
  font-weight: var(--h3-style-font-weight);
  letter-spacing: var(--h3-style-letter-spacing);
  line-height: var(--h3-style-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .element {
  color: var(--variable-collection-primary-dark);
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.website-layer .how-we-do-it-section {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  padding: 90px 72px;
  position: relative;
  width: 1278px;
}

.website-layer .title-2 {
  height: 46px;
  position: relative;
  width: 263px;
}

.website-layer .overlap-group-6 {
  height: 46px;
  position: relative;
  width: 261px;
}

.website-layer .line-5 {
  height: 7px;
  left: 103px;
  position: absolute;
  top: 32px;
  width: 154px;
}

.website-layer .how-we-do-it {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 400;
  left: 0;
  letter-spacing: 1.08px;
  line-height: 46.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.website-layer .w-rapper-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 36px 0px 0px;
  position: relative;
}

.website-layer .w-rapper {
  height: 959px;
  position: relative;
  width: 784px;
}

.website-layer .overlap-6 {
  height: 959px;
  position: relative;
}

.website-layer .frame-16 {
  height: 946px;
  left: 0;
  position: absolute;
  top: 0;
  width: 361px;
}

.website-layer .content-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: -36px;
  width: 361px;
}

.website-layer .text-wrapper-31 {
  align-self: stretch;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.84px;
  line-height: 36.1px;
  position: relative;
}

.website-layer .text-wrapper-32 {
  align-self: stretch;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  opacity: 0.8;
  position: relative;
}

.website-layer .content-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: 342px;
  width: 361px;
}

.website-layer .img-wrapper {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.website-layer .watcher {
  height: 33px;
  object-fit: cover;
  position: relative;
  width: 33px;
}

.website-layer .content-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: 733px;
  width: 361px;
}

.website-layer .img-3 {
  height: 35px;
  object-fit: cover;
  position: relative;
  width: 35px;
}

.website-layer .frame-17 {
  height: 459px;
  left: 420px;
  position: absolute;
  top: 147px;
  width: 361px;
}

.website-layer .content-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: 15px;
  width: 361px;
}

.website-layer .text-wrapper-33 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.84px;
  line-height: 36.1px;
  margin-right: -30.00px;
  position: relative;
  width: 373px;
}

.website-layer .content-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: 394px;
  width: 361px;
}

.website-layer .group-13 {
  height: 959px;
  left: 0;
  position: absolute;
  top: 0;
  width: 784px;
}

.website-layer .lines {
  height: 947px;
  left: 0;
  position: absolute;
  top: 0;
  width: 784px;
}

.website-layer .overlap-group-7 {
  height: 947px;
  left: 392px;
  position: absolute;
  top: 0;
  width: 392px;
}

.website-layer .line-6 {
  height: 947px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

.website-layer .line-7 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 360px;
  width: 392px;
}

.website-layer .line-8 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 760px;
  width: 392px;
}

.website-layer .line-9 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 160px;
  width: 392px;
}

.website-layer .line-10 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 392px;
}

.website-layer .line-11 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 962px;
  width: 392px;
}

.website-layer .point {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 827px;
  justify-content: space-between;
  left: 381px;
  position: absolute;
  top: 132px;
  width: 22px;
}

.website-layer .timelineDot {
  align-self: stretch;
  background-color: var(--variable-collection-primary-dark);
  border-radius: 11px;
  height: 22px;
  position: relative;
  width: 100%;
}

.website-layer .about-section {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 90px 0px;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
  background-color: #0f1a1b;

}

.website-layer .about-component {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 60px 20vw;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
}

.website-layer .about-mission-component {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 60px 20vw;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
}

.website-layer .our-mission {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 90px 0px;
  position: relative;
  width: 100vw;
  box-sizing: border-box;

}

.website-layer .col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 98px;
  height: 447px;
  position: relative;
  width: 417px;
  justify-content: space-between;
}

.website-layer .div-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.website-layer .text-wrapper-34 {
  color: var(--primary-light);
  font-family: var(--h2-style-font-family);
  font-size: var(--h2-style-font-size);
  font-style: var(--h2-style-font-style);
  font-weight: var(--h2-style-font-weight);
  letter-spacing: var(--h2-style-letter-spacing);
  line-height: var(--h2-style-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 311px;
}

.website-layer .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 5px 0px 0px;
  position: relative;
}

.website-layer .frame-19 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 222px;
}

.website-layer .group-14 {
  background-color: var(--variable-collection-primary-light);
  border-radius: 4.5px;
  height: 9px;
  position: relative;
  width: 9px;
}

.website-layer .bullet-point {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  margin-right: -46.00px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .our-expert-team-of {
  align-self: stretch;
  color: var(--primary-light);
  font-family: var(--h3-style-font-family);
  font-size: var(--h3-style-font-size);
  font-style: var(--h3-style-font-style);
  font-weight: var(--h3-style-font-weight);
  height: 120px;
  letter-spacing: var(--h3-style-letter-spacing);
  line-height: var(--h3-style-line-height);
  margin-bottom: -16.00px;
  position: relative;
}

.website-layer .our-secured-brands {
  align-self: stretch;
  /* color: var(--primary-light); */
  font-family: var(--h3-style-font-family);
  font-size: var(--h3-style-font-size);
  font-style: var(--h3-style-font-style);
  font-weight: var(--h3-style-font-weight);
  height: 120px;
  letter-spacing: var(--h3-style-letter-spacing);
  line-height: var(--h3-style-line-height);
  margin-bottom: -16.00px;
  position: relative;
}

.website-layer .col-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 440px;
  justify-content: space-between;
  position: relative;
}

.website-layer .text-wrapper-36 {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.84px;
  line-height: 36.1px;
  margin-top: -1.00px;
  position: relative;
  width: 311px;
}

.website-layer .USP {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 40px 73px;
  height: 215px;
  padding: 0px 10px;
  position: relative;
  width: 325px;
}

.website-layer .frame-20 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 120px;
}

.website-layer .trustIcon {
  height: 40px;
  position: relative;
  width: 40px;
}
.website-layer .shieldIcon {
  height: 80px;
  position: relative;
  width: 80px;
}

.website-layer .text-wrapper-37 {
  align-self: stretch;
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  position: relative;
}

.website-layer .certificates {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 22px;
  position: relative;
}

.website-layer .certificateIcon {
  position: relative;
  width: 20vw;
}

.website-layer .trustedBrands {
  position: relative;
  /* width: 20vw; */
}

.website-layer .retented-customers {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 90px 0px;
  position: relative;
  width: 562px;
}

.website-layer .wrapper-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  padding: 180px 72px;
  position: relative;
}

.website-layer .group-15 {
  height: 393px;
  left: 31px;
  position: absolute;
  top: 47px;
  width: 522px;
}

.website-layer .group-16 {
  height: 489px;
  left: -32px;
  position: absolute;
  top: -3px;
  width: 649px;
}

.website-layer .blackbuck-logo-wrapper {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 554px;
  padding: 2.86px;
  position: absolute;
  top: 0;
}

.website-layer .blackbuck-logo {
  height: 57.27px;
  object-fit: cover;
  position: relative;
  width: 57.27px;
}

.website-layer .frame-21 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 289px;
  padding: 2.86px;
  position: absolute;
  top: 32px;
}

.website-layer .frame-22 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 32px;
  padding: 2.86px;
  position: absolute;
  top: 3px;
}

.website-layer .frame-23 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 0;
  padding: 2.86px;
  position: absolute;
  top: 214px;
}

.website-layer .frame-24 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 32px;
  padding: 2.86px;
  position: absolute;
  top: 426px;
}

.website-layer .frame-25 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 289px;
  padding: 2.86px;
  position: absolute;
  top: 391px;
}

.website-layer .frame-26 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 539px;
  padding: 2.86px;
  position: absolute;
  top: 423px;
}

.website-layer .frame-27 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 586px;
  padding: 2.86px;
  position: absolute;
  top: 214px;
}

.website-layer .title-3 {
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px solid;
  border-color: var(--variable-collection-primary-light);
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 24px;
  position: relative;
}

.website-layer .line-12 {
  height: 5px;
  left: 76px;
  position: absolute;
  top: 91px;
  width: 116px;
}

.website-layer .retained-customers {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.website-layer .testimonial-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 52px;
  max-width: 100%;
  padding: 90px 12vw;
  position: relative;
  /* width: 100%; */
}

.website-layer .title-4 {
  height: 92px;
  position: relative;
  width: 400px;
}

.website-layer .overlap-group-8 {
  height: 92px;
  position: relative;
  width: 398px;
}

.website-layer .line-13 {
  height: 7px;
  left: 251px;
  position: absolute;
  top: 29px;
  width: 135px;
}

.website-layer .testimonial-wrap {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  margin-right: -30.00px;
  position: relative;
  width: 80vw;
}

.website-layer .testimonial-card {
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px solid;
  border-color: var(--variable-collection-primary-light);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  margin-left: -133.50px;
  padding: 32px;
  position: relative;
}

.website-layer .comprehensive-2 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.54px;
  line-height: 23.2px;
  margin-top: -1.00px;
  position: relative;
  width: 387px;
}

.website-layer .frame-28 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.website-layer .IMG-wa {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 28.92px;
}

.website-layer .frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.website-layer .text-wrapper-38 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 15.5px;
  opacity: 0.8;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .testimonial-card-2 {
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px solid;
  border-color: var(--variable-collection-primary-light);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  padding: 32px;
  position: relative;
}

.website-layer .IMG-2 {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 58.42px;
}

.website-layer .testimonial-card-3 {
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px solid;
  border-color: var(--variable-collection-primary-light);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  margin-right: -133.50px;
  padding: 32px;
  position: relative;
}

.website-layer .team-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  padding: 90px 0px;
  position: relative;
  width: 100%;
}

.website-layer .text-wrapper-39 {
  color: #0f1a1b;
  font-family: var(--h2-style-font-family);
  font-size: var(--h2-style-font-size);
  font-style: var(--h2-style-font-style);
  font-weight: var(--h2-style-font-weight);
  letter-spacing: var(--h2-style-letter-spacing);
  line-height: var(--h2-style-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .cards {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  position: relative;
  width: 933px;
}

.website-layer .card-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 279px;
}

.website-layer .image {
  height: 156px;
  object-fit: cover;
  position: relative;
  width: 163px;
}

.website-layer .frame-30 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 243px;
}

.website-layer .frame-31 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.website-layer .frame-32 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.website-layer .CTA-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  background-color: #0f1a1b;
  justify-content: space-around;
  box-sizing: border-box;
  align-items: center;
  padding: 10px 10vw;
}

.website-layer .networkLinesWrapper {
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: flex-end;
}

.website-layer .networkLines {
  max-width: 100px;
}

.website-layer .networkLinesWrapper1 {
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: flex-start;
}

.website-layer .networkLines1 {
  max-width: 100px;
  transform: rotate(180deg);
}

.website-layer .header-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.website-layer .text-wrapper-40 {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 1.08px;
  line-height: 46.4px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.website-layer .footer {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-light);
  display: flex;
  height: 519px;
  justify-content: space-between;
  padding: 90px 10vw 30px 10vw;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.website-layer .frame-33 {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 40vh;
  justify-content: space-between;
  position: relative;
  width: 100vw;
}

.website-layer .brand-logo-dk {
  background-size: 100% 100%;
  position: relative;
}

.website-layer .frame-34 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 35vh;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.website-layer .social-icon {
  height: 25px;
  position: relative;
  width: 180px;
  display: flex;
  justify-content: space-evenly;
}

.website-layer .youtube {
  height: 24px;
  left: 190px;
  position: absolute;
  top: 0;
  width: 24px;
}



.website-layer .twitter {
  height: 24px;
  left: 143px;
  position: absolute;
  top: 0;
  width: 24px;
}


.website-layer .linkedin {
  height: 24px;
  left: 102px;
  position: absolute;
  top: 0;
  width: 24px;
}

.website-layer .privacy-policy-terms {
  align-self: flex-end;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  opacity: 0.8;
  position: relative;
}

.website-layer .rights-reserved {
  align-self: flex-end;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  opacity: 0.8;
  margin-top: 30px;
  position: relative;
}

.website-layer .flinks {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 64px;
  position: relative;
}

.website-layer .frame-35 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
  width: max-content;
}

.website-layer .text-wrapper-45 {
  align-self: stretch;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.66px;
  line-height: 28.4px;
  margin-top: -1.00px;
  position: relative;
}

.website-layer .frame-36 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  position: relative;
  width: max-content;
}

.website-layer .flink-options {
  align-self: stretch;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  opacity: 0.8;
  position: relative;
}

/* Media query for small screens */
@media (max-width: 768px) {

  .website-layer .navbar {
    height: 5vh;
    max-height: 5vh;
    padding: 1vw 4vw;
  }
  .website-layer .hero-section {
    height: 44vh; /* Adjust height for small screens */
    max-height: 44vh;
  }
}
