@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.website-layer {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.website-layer .darkeye-website-v-layer {
  background-color: #ffffff;
  /* height: 8197px; */
  width: 100%;
}
.website-layer .darkeye-socialIcon132 {
    width: 30px;
}

.website-layer .darkeye-main-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.website-layer .darkeye-darkeye-hero-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-image: url('./assests/darkeye-hero-bg.svg'); 
  background-size: contain; 
  background-position: center center; 
  height: 88vh; 
  margin: 0; 
  max-height: 88vh;
}

.website-layer .darkeye-section1-text {
    font-size: 40px;
}

.website-layer .darkeye-navbar2 {
  align-items: center;
  background-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: rgba(212,212,212, 0.5);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 28px 10vw;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 13vh;
  max-height: 13vh;
}

.website-layer .darkeye-brand-logo-lt {
  background-image: url(./assests/brand-logo-lt.svg);
  background-size: 100% 100%;
  height: 58.3px;
  position: relative;
  width: 180.09px;
}

.website-layer .darkeye-navlinks {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.website-layer .darkeye-links {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 25px;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: 98px;
  cursor: pointer;
}

.website-layer .darkeye-nav-btn2 {
  align-items: center;
  background-color: #000;
  border: 1px solid;
  border-color: #d4d4d4;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 12px 24px;
  position: relative;
}

.website-layer .darkeye-download-btn {
  align-items: center;
  background-color: #0f1a1b;
  border: 1px solid;
  border-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 25px 50px;
  position: relative;
}

.website-layer .darkeye-text-wrapper-42 {
  color: #d4d4d4;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-hero-section-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 701px;
  justify-content: center;
  /* padding: 80px 180px; */
  position: relative;
  width: 100%;
  height: 100vh;
  margin: auto;
  /* width: 1278px; */
}

.website-layer .darkeye-hero-header {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.website-layer .darkeye-defend-against-cyber {
  color: transparent;
  font-family: var(--h1-style-font-family);
  font-size: 55px;
  font-style: var(--h1-style-font-style);
  font-weight: var(--h1-style-font-weight);
  letter-spacing: var(--h1-style-letter-spacing);
  line-height: var(--h1-style-line-height);
  position: relative;
  text-align: center;
  width: 50vw;
  margin: 0px !important;

}

.website-layer .darkeye-span {
  color: #f4fffb;
  font-family: var(--h1-style-font-family);
  font-size: 65px;
  font-style: var(--h1-style-font-style);
  font-weight: var(--h1-style-font-weight);
  letter-spacing: var(--h1-style-letter-spacing);
  line-height: var(--h1-style-line-height);
}

.website-layer .darkeye-text-wrapper-5 {
  color: #00ffb2;
  font-family: var(--h1-style-font-family);
  font-size: 65px;
  font-style: var(--h1-style-font-style);
  font-weight: var(--h1-style-font-weight);
  letter-spacing: var(--h1-style-letter-spacing);
  line-height: var(--h1-style-line-height);
}

.website-layer .darkeye-p {
  color: #f4fffbcc;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 60vw;
  margin: 10px 0px 0px 0px  !important;
  padding: 0px !important
}

.website-layer .darkeye-div-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 25px 50px;
  position: relative;
  margin-left: 30px;
}

.website-layer .darkeye-section-div-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 25px 50px;
  position: relative;
  margin-left: 30px;
}

.website-layer .darkeye-text-wrapper-6 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-text-wrapper-section {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-trust {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 48px;
  margin-left: -64.50px;
  margin-right: -64.50px;
  padding: 100px 0px 0px;
  position: relative;
}

.website-layer .darkeye-overall-rating {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-end;
  position: relative;
}

.website-layer .darkeye-frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.website-layer .darkeye-IMG {
  height: 39px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 41px;
}

.website-layer .darkeye-img {
  flex: 0 0 auto;
  position: relative;
}

.website-layer .darkeye-frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  padding: 0px 0px 14px;
  position: relative;
}

.website-layer .darkeye-img-2 {
  /* height: 42.81px; */
  object-fit: cover;
  position: relative;
  width: 50vw;
}

.website-layer .darkeye-services-section2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 39px;
  justify-content: center;
  padding: 20vh 72px;
  position: relative;
  width: 85vw;
  box-sizing: border-box;
}

.website-layer .darkeye-section2-heading {
font-size: 40px;
color: #f5fffc;
}

.website-layer .darkeye-section3-heading {
    font-size: 40px;
    color: #000;
    }

.website-layer .darkeye-div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  position: relative;
}

.website-layer .darkeye-primary-btn-instance2 {
  background-color:rgba(212,212,212, 0.2) !important;
  display: inline-flex !important;
  flex: 0 0 auto !important;
  height: unset !important;
  padding: 8px 16px !important;
  width: unset !important;
  border: none;
  border-radius: 10px;
  color: "#006c4c";
  font-size: 15px;
}

.website-layer .darkeye-design-component-instance-node {
  color: #006c4b !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.42px !important;
  line-height: 18.1px !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: -1.00px !important;
}

.website-layer .darkeye-title {
  height: 92px;
  margin-right: -2.00px;
  position: relative;
  width: 450px;
}

.website-layer .darkeye-overlap-group-2 {
  height: 92px;
  position: relative;
  width: 448px;
}

.website-layer .darkeye-line {
  height: 7px;
  left: 273px;
  position: absolute;
  top: 75px;
  width: 175px;
}

.website-layer .darkeye-text-wrapper-8 {
  color: #0f1a1b;
  font-family: var(--h2-style-font-family);
  font-size: var(--h2-style-font-size);
  font-style: var(--h2-style-font-style);
  font-weight: var(--h2-style-font-weight);
  left: 0;
  letter-spacing: var(--h2-style-letter-spacing);
  line-height: var(--h2-style-line-height);
  position: absolute;
  top: 0;
}

.website-layer .darkeye-services-card {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 70px;
  justify-content: center;
  margin-right: -1.00px;
  position: relative;
}

.website-layer .darkeye-small-cards {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: space-around;
  position: relative;
  /* width: 85vw; */
}

.website-layer .darkeye-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 35px 5px;
  position: relative;
  justify-content: space-between;
}

.website-layer .darkeye-card {
  align-items: flex-start;
  background-color: #d3d3d3;
  border: 1px solid;
  border-color: #03000033;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 30vh;
  padding: 30px 20px;
  position: relative;
  width: 22vw;
}

.website-layer .darkeye-frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  position: relative;
  width: 100%;
}

.website-layer .darkeye-frame-4 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  margin-right: -22.00px;
  padding: 0px 9px;
  position: relative;
  width: 320px;
}

.website-layer .darkeye-cardHeader {
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.84px;
  line-height: 36.1px;
  margin-top: -1.00px;
  position: relative;
  width: 282px;
}

.website-layer .darkeye-frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.website-layer .darkeye-frame-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 11px;
  position: relative;
  width: 100%;
}

.website-layer .darkeye-group-wrapper {
  height: 17px;
  position: relative;
  width: 20px;
}

.website-layer .darkeye-group-2 {
  height: 17px;
}

.website-layer .darkeye-overlap-group-3 {
  height: 18px;
  position: relative;
  top: -1px;
  width: 21px;
}

.website-layer .darkeye-ellipse {
  background-color: #030000;
  border-radius: 8.5px;
  height: 17px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 1px;
  width: 17px;
}

.website-layer .darkeye-vector {
  height: 13px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 18px;
}

.website-layer .darkeye-cardPointer {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  margin-top: -1.00px;
  position: relative;
  width: 264px;
}

.website-layer .darkeye-card-1 {
  align-items: flex-start;
  background-color: #e0fcff;
  border: 1px solid;
  border-color: #021f2233;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 30vh;
  padding: 30px 20px;
  position: relative;
  width: 22vw;
}

.website-layer .darkeye-frame-7 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  margin-right: -23.00px;
  padding: 0px 9px;
  position: relative;
  width: 320px;
}


.website-layer .darkeye-text-wrapper-11 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.website-layer .darkeye-frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 11px;
  position: relative;
}

.website-layer .darkeye-card-2 {
  align-items: flex-start;
  background-color: #ffe2e2;
  border: 1px solid;
  border-color: #18010133;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 30vh;
  padding: 30px 20px;
  position: relative;
  width: 22vw;
}


.website-layer .darkeye-card-3 {
  align-items: flex-start;
  background-color: #cff5da;
  border: 1px solid;
  border-color: #015e1c33;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 30vh;
  padding: 30px 20px;
  position: relative;
  width: 22vw;
}

.website-layer .darkeye-card-big {
  align-items: flex-start;
  background-color: rgba(245, 255, 252, 1);
  border: 1px solid;
  border-color: #015e1c33;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 69vh;
  padding: 30px 20px;
  position: relative;
  width: 22vw;
}


.website-layer .darkeye-overlap-group-wrapper {
  height: 17px;
  width: 20px;
}

.website-layer .darkeye-big-card {
  align-self: stretch;
  position: relative;
  width: 376px;
}

.website-layer .darkeye-report-section2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 90px 10vw;
  position: relative;
  width: 100vw;
  background-color: #0a0a0a;
  box-sizing: border-box;
}

.website-layer .darkeye-report-section3 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 90px 10vw;
    position: relative;
    width: 100vw;
    background-color: #fff;
    box-sizing: border-box;
  }

.website-layer .darkeye-report-component131 {
  align-items: center;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  padding: 1vw 5vw;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
  gap: 10vw;
}

.website-layer .darkeye-content-1 {
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  /* width: 30vw; */
  margin-top: 50px;
  justify-content: flex-start;
}


.website-layer .darkeye-frame-9 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 86px;
  padding: 0px 9px;
  position: relative;
  width: 320px;
}

.website-layer .darkeye-frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  margin-right: -9.00px;
  position: relative;
}

.website-layer .darkeye-text-wrapper-13 {
  color: var(--primary-light);
  font-family: var(--h2-small-font-family);
  font-size: var(--h2-small-font-size);
  font-style: var(--h2-small-font-style);
  font-weight: var(--h2-small-font-weight);
  letter-spacing: var(--h2-small-letter-spacing);
  line-height: var(--h2-small-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 282px;
}

.website-layer .darkeye-text-wrapper-131 {
  color: var(--primary-light);
  font-family: var(--h2-small-font-family);
  font-size: 20px;
  font-style: var(--h2-small-font-style);
  font-weight: var(--h2-small-font-weight);
  letter-spacing: var(--h2-small-letter-spacing);
  line-height: var(--h2-small-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 282px;
}

.website-layer .darkeye-text-wrapper-132 {
    color: #000;
    font-family: var(--h2-small-font-family);
    font-size: 22px;
    font-style: var(--h2-small-font-style);
    font-weight: 600;
    letter-spacing: var(--h2-small-letter-spacing);
    line-height: var(--h2-small-line-height);
    margin-top: -1.00px;
    position: relative;
    width: 282px;
  }

.website-layer .darkeye-comprehensive131 {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  opacity: 0.8;
  position: relative;
  text-align: left;
  /* width: 30vw; */
  margin-bottom: 50px;
}

.website-layer .darkeye-comprehensive132 {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.60px;
    line-height: 25.8px;
    opacity: 0.8;
    position: relative;
    text-align: left;
    /* width: 30vw; */
    margin-bottom: 50px;
  }

.website-layer .darkeye-primary-btn-2 {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0.48px !important;
  line-height: 20.6px !important;
}

.website-layer .darkeye-primary-btn-3 {
  flex: 0 0 auto !important;
}

.website-layer .darkeye-report-img {
  margin-bottom: -11.99px;
  margin-right: -11.99px;
  margin-top: -11.98px;
  position: relative;
  width: 25vw;
}

.website-layer .darkeye-stats-section-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 72px;
  padding: 90px 72px;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
  background-color: #000;
}

.website-layer .darkeye-div-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 85vw;
}

.website-layer .darkeye-header {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 412px;
  justify-content: space-between;
  position: relative;
  width: 39vw;
}

.website-layer .darkeye-div-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 36px;
  position: relative;
}

.website-layer .darkeye-group-3 {
  height: 138px;
  margin-right: -2.00px;
  position: relative;
  width: 535px;
}

.website-layer .darkeye-overlap-group-4 {
  height: 138px;
  position: relative;
  width: 533px;
}

.website-layer .darkeye-line-2 {
  height: 7px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 407px;
}

.website-layer .darkeye-all-in-one-cyber {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  opacity: 0.8;
  position: relative;
  width: 533px;
}

.website-layer .darkeye-frame-11 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 533px;
}

.website-layer .darkeye-text-wrapper-141 {
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.website-layer .darkeye-lines-of-code {
  align-self: stretch;
  color: #0f1a1b;
  font-family: var(--h3-style-font-family);
  font-size: var(--h3-style-font-size);
  font-style: var(--h3-style-font-style);
  font-weight: var(--h3-style-font-weight);
  letter-spacing: var(--h3-style-letter-spacing);
  line-height: var(--h3-style-line-height);
  position: relative;
}

.website-layer .darkeye-frame-12 {
  background-image: url(./assests/frame-313.png); 
  background-size: 100% 100%;
  height: 412px;
  position: relative;
  width: 563px;
}

.website-layer .darkeye-overlap-wrapper {
  height: 102px;
  left: 138px;
  position: absolute;
  top: 113px;
  transform: rotate(-90deg);
  width: 123px;
}

.website-layer .darkeye-overlap {
  height: 102px;
  position: relative;
}

.website-layer .darkeye-group-4 {
  height: 102px;
  left: 0;
  position: absolute;
  top: 0;
  width: 123px;
}

.website-layer .darkeye-overlap-2 {
  height: 102px;
  position: relative;
  width: 115px;
}

.website-layer .darkeye-text-wrapper-15 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 6px;
  position: absolute;
  text-align: right;
  top: 9px;
  transform: rotate(45deg);
  width: 28px;
}

.website-layer .darkeye-text-wrapper-16 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 6px;
  position: absolute;
  text-align: right;
  top: 32px;
  transform: rotate(45deg);
  width: 28px;
}

.website-layer .darkeye-overlap-group-5 {
  height: 86px;
  left: 9px;
  position: absolute;
  top: 14px;
  width: 38px;
}

.website-layer .darkeye-text-wrapper-17 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: -4px;
  letter-spacing: 0;
  line-height: 6px;
  position: absolute;
  text-align: right;
  top: 41px;
  transform: rotate(45deg);
  width: 42px;
}

.website-layer .darkeye-line-3 {
  height: 1px;
  left: -5px;
  position: absolute;
  top: 42px;
  transform: rotate(90deg);
  width: 86px;
}

.website-layer .darkeye-text-wrapper-18 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: -5px;
  letter-spacing: 0;
  line-height: 6px;
  position: absolute;
  text-align: right;
  top: 77px;
  transform: rotate(45deg);
  width: 52px;
}

.website-layer .darkeye-rectangle {
  background-color: var(--primary);
  height: 11px;
  left: 46px;
  position: absolute;
  top: 19px;
  width: 16px;
}

.website-layer .darkeye-rectangle-2 {
  background-color: var(--primary);
  height: 12px;
  left: 46px;
  position: absolute;
  top: 42px;
  width: 31px;
}

.website-layer .darkeye-rectangle-3 {
  background-color: var(--primary);
  height: 12px;
  left: 46px;
  position: absolute;
  top: 64px;
  width: 49px;
}

.website-layer .darkeye-rectangle-4 {
  background-color: #00ffb2;
  height: 12px;
  left: 46px;
  position: absolute;
  top: 88px;
  width: 69px;
}

.website-layer .darkeye-text-wrapper-19 {
  color: #0f1a1b;
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 50px;
  letter-spacing: 0;
  line-height: 6.0px;
  position: absolute;
  text-align: right;
  top: 18px;
  transform: rotate(90deg);
  width: 14px;
}

.website-layer .darkeye-text-wrapper-20 {
  color: #0f1a1b;
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0;
  line-height: 6.0px;
  position: absolute;
  text-align: right;
  top: 41px;
  transform: rotate(90deg);
  width: 14px;
}

.website-layer .darkeye-text-wrapper-21 {
  color: #0f1a1b;
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 82px;
  letter-spacing: 0;
  line-height: 6.0px;
  position: absolute;
  text-align: right;
  top: 64px;
  transform: rotate(90deg);
  width: 14px;
}

.website-layer .darkeye-text-wrapper-22 {
  color: #0f1a1b;
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 101px;
  letter-spacing: 0;
  line-height: 6.0px;
  position: absolute;
  text-align: right;
  top: 87px;
  transform: rotate(90deg);
  width: 14px;
}

.website-layer .darkeye-group-5 {
  height: 25px;
  left: 46px;
  position: absolute;
  top: 184px;
  width: 62px;
}

.website-layer .darkeye-overlap-3 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 63px;
}

.website-layer .darkeye-group-6 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.website-layer .darkeye-rectangle-5 {
  background-color: #2d146f;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .darkeye-text-wrapper-23 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 24px;
}

.website-layer .darkeye-group-7 {
  height: 4px;
  left: 28px;
  position: absolute;
  top: 0;
  width: 35px;
}

.website-layer .darkeye-rectangle-6 {
  background-color: #8b5fe5;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .darkeye-text-wrapper-24 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 26px;
}

.website-layer .darkeye-group-8 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 64px;
}

.website-layer .darkeye-rectangle-7 {
  background-color: #1eebad;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .darkeye-text-wrapper-25 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 56px;
}

.website-layer .darkeye-group-9 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 56px;
}

.website-layer .darkeye-rectangle-8 {
  background-color: #489edb;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .darkeye-text-wrapper-26 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 47px;
}

.website-layer .darkeye-overlap-4 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 21px;
  width: 60px;
}

.website-layer .darkeye-group-10 {
  height: 4px;
  left: 28px;
  position: absolute;
  top: 0;
  width: 32px;
}

.website-layer .darkeye-rectangle-9 {
  background-color: #9becd4;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .darkeye-group-11 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 29px;
}

.website-layer .darkeye-rectangle-10 {
  background-color: #2c009e;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.website-layer .darkeye-text-wrapper-27 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 4.3px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: 4.3px;
  position: absolute;
  top: 0;
  width: 21px;
}

.website-layer .darkeye-group-12 {
  height: 87px;
  left: 203px;
  position: absolute;
  top: 288px;
  width: 326px;
}

.website-layer .darkeye-frame-13 {
  align-items: center;
  display: flex;
  height: 8px;
  justify-content: space-between;
  left: 15px;
  position: absolute;
  top: 79px;
  width: 311px;
}

.website-layer .darkeye-text-wrapper-28 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 5.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 5.8px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-frame-14 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 76px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 11px;
}

.website-layer .darkeye-text-wrapper-29 {
  color: var(--primary-light);
  font-family: "Cutive Mono-Regular", Helvetica;
  font-size: 5.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 5.8px;
  margin-top: -0.64px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-line-4 {
  height: 1px;
  left: 15px;
  position: absolute;
  top: 74px;
  width: 311px;
}

.website-layer .darkeye-overlap-5 {
  height: 52px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 310px;
}

.website-layer .darkeye-vector-2 {
  height: 52px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 309px;
}

.website-layer .darkeye-vector-3 {
  height: 36px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 308px;
}

.website-layer .darkeye-vector-4 {
  height: 29px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 308px;
}

.website-layer .darkeye-vector-5 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 308px;
}

.website-layer .darkeye-frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.website-layer .darkeye-text-wrapper-301 {
  color: #FFF;
  font-family: var(--h3-style-font-family);
  font-size: var(--h3-style-font-size);
  font-style: var(--h3-style-font-style);
  font-weight: var(--h3-style-font-weight);
  letter-spacing: var(--h3-style-letter-spacing);
  line-height: var(--h3-style-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-element {
  color: var(--variable-collection-primary-dark);
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.website-layer .darkeye-how-we-do-it-section {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  padding: 90px 72px;
  position: relative;
  width: 1278px;
}

.website-layer .darkeye-title-2 {
  height: 46px;
  position: relative;
  width: 263px;
}

.website-layer .darkeye-overlap-group-6 {
  height: 46px;
  position: relative;
  width: 261px;
}

.website-layer .darkeye-line-5 {
  height: 7px;
  left: 103px;
  position: absolute;
  top: 32px;
  width: 154px;
}

.website-layer .darkeye-how-we-do-it {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 400;
  left: 0;
  letter-spacing: 1.08px;
  line-height: 46.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.website-layer .darkeye-w-rapper-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 36px 0px 0px;
  position: relative;
}

.website-layer .darkeye-w-rapper {
  height: 959px;
  position: relative;
  width: 784px;
}

.website-layer .darkeye-overlap-6 {
  height: 959px;
  position: relative;
}

.website-layer .darkeye-frame-16 {
  height: 946px;
  left: 0;
  position: absolute;
  top: 0;
  width: 361px;
}

.website-layer .darkeye-content-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: -36px;
  width: 361px;
}

.website-layer .darkeye-text-wrapper-31 {
  align-self: stretch;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.84px;
  line-height: 36.1px;
  position: relative;
}

.website-layer .darkeye-text-wrapper-32 {
  align-self: stretch;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  opacity: 0.8;
  position: relative;
}

.website-layer .darkeye-content-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: 342px;
  width: 361px;
}

.website-layer .darkeye-img-wrapper {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.website-layer .darkeye-watcher {
  height: 33px;
  object-fit: cover;
  position: relative;
  width: 33px;
}

.website-layer .darkeye-content-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: 733px;
  width: 361px;
}

.website-layer .darkeye-img-3 {
  height: 35px;
  object-fit: cover;
  position: relative;
  width: 35px;
}

.website-layer .darkeye-frame-17 {
  height: 459px;
  left: 420px;
  position: absolute;
  top: 147px;
  width: 361px;
}

.website-layer .darkeye-content-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: 15px;
  width: 361px;
}

.website-layer .darkeye-text-wrapper-33 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.84px;
  line-height: 36.1px;
  margin-right: -30.00px;
  position: relative;
  width: 373px;
}

.website-layer .darkeye-content-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  padding: 0px 9px 12px;
  position: absolute;
  top: 394px;
  width: 361px;
}

.website-layer .darkeye-group-13 {
  height: 959px;
  left: 0;
  position: absolute;
  top: 0;
  width: 784px;
}

.website-layer .darkeye-lines {
  height: 947px;
  left: 0;
  position: absolute;
  top: 0;
  width: 784px;
}

.website-layer .darkeye-overlap-group-7 {
  height: 947px;
  left: 392px;
  position: absolute;
  top: 0;
  width: 392px;
}

.website-layer .darkeye-line-6 {
  height: 947px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

.website-layer .darkeye-line-7 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 360px;
  width: 392px;
}

.website-layer .darkeye-line-8 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 760px;
  width: 392px;
}

.website-layer .darkeye-line-9 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 160px;
  width: 392px;
}

.website-layer .darkeye-line-10 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 392px;
}

.website-layer .darkeye-line-11 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 962px;
  width: 392px;
}

.website-layer .darkeye-point {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 827px;
  justify-content: space-between;
  left: 381px;
  position: absolute;
  top: 132px;
  width: 22px;
}

.website-layer .darkeye-timelineDot {
  align-self: stretch;
  background-color: var(--variable-collection-primary-dark);
  border-radius: 11px;
  height: 22px;
  position: relative;
  width: 100%;
}

.website-layer .darkeye-about-section {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 90px 0px;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
  background-color: #0f1a1b;

}

.website-layer .darkeye-about-component {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 60px 20vw;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
}

.website-layer .darkeye-about-mission-component {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 60px 20vw;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
}

.website-layer .darkeye-our-mission {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 90px 0px;
  position: relative;
  width: 100vw;
  box-sizing: border-box;

}

.website-layer .darkeye-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 98px;
  height: 447px;
  position: relative;
  width: 417px;
  justify-content: space-between;
}

.website-layer .darkeye-div-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.website-layer .darkeye-text-wrapper-34 {
  color: var(--primary-light);
  font-family: var(--h2-style-font-family);
  font-size: var(--h2-style-font-size);
  font-style: var(--h2-style-font-style);
  font-weight: var(--h2-style-font-weight);
  letter-spacing: var(--h2-style-letter-spacing);
  line-height: var(--h2-style-line-height);
  margin-top: -1.00px;
  position: relative;
  width: 311px;
}

.website-layer .darkeye-frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 5px 0px 0px;
  position: relative;
}

.website-layer .darkeye-frame-19 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 222px;
}

.website-layer .darkeye-group-14 {
  background-color: var(--variable-collection-primary-light);
  border-radius: 4.5px;
  height: 9px;
  position: relative;
  width: 9px;
}

.website-layer .darkeye-bullet-point {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  margin-right: -46.00px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-our-expert-team-of {
  align-self: stretch;
  color: var(--primary-light);
  font-family: var(--h3-style-font-family);
  font-size: var(--h3-style-font-size);
  font-style: var(--h3-style-font-style);
  font-weight: var(--h3-style-font-weight);
  height: 120px;
  letter-spacing: var(--h3-style-letter-spacing);
  line-height: var(--h3-style-line-height);
  margin-bottom: -16.00px;
  position: relative;
}

.website-layer .darkeye-our-secured-brands {
  align-self: stretch;
  /* color: var(--primary-light); */
  font-family: var(--h3-style-font-family);
  font-size: var(--h3-style-font-size);
  font-style: var(--h3-style-font-style);
  font-weight: var(--h3-style-font-weight);
  height: 120px;
  letter-spacing: var(--h3-style-letter-spacing);
  line-height: var(--h3-style-line-height);
  margin-bottom: -16.00px;
  position: relative;
}

.website-layer .darkeye-col-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 440px;
  justify-content: space-between;
  position: relative;
}

.website-layer .darkeye-text-wrapper-36 {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0.84px;
  line-height: 36.1px;
  margin-top: -1.00px;
  position: relative;
  width: 311px;
}

.website-layer .darkeye-USP {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 40px 73px;
  height: 215px;
  padding: 0px 10px;
  position: relative;
  width: 325px;
}

.website-layer .darkeye-frame-20 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 120px;
}

.website-layer .darkeye-trustIcon {
  height: 40px;
  position: relative;
  width: 40px;
}
.website-layer .darkeye-shieldIcon {
  height: 80px;
  position: relative;
  width: 80px;
}

.website-layer .darkeye-text-wrapper-37 {
  align-self: stretch;
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  position: relative;
}

.website-layer .darkeye-certificates {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 22px;
  position: relative;
}

.website-layer .darkeye-certificateIcon {
  position: relative;
  width: 20vw;
}

.website-layer .darkeye-trustedBrands {
  position: relative;
  /* width: 20vw; */
}

.website-layer .darkeye-retented-customers {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 90px 0px;
  position: relative;
  width: 562px;
}

.website-layer .darkeye-wrapper-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  padding: 180px 72px;
  position: relative;
}

.website-layer .darkeye-group-15 {
  height: 393px;
  left: 31px;
  position: absolute;
  top: 47px;
  width: 522px;
}

.website-layer .darkeye-group-16 {
  height: 489px;
  left: -32px;
  position: absolute;
  top: -3px;
  width: 649px;
}

.website-layer .darkeye-blackbuck-logo-wrapper {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 554px;
  padding: 2.86px;
  position: absolute;
  top: 0;
}

.website-layer .darkeye-blackbuck-logo {
  height: 57.27px;
  object-fit: cover;
  position: relative;
  width: 57.27px;
}

.website-layer .darkeye-frame-21 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 289px;
  padding: 2.86px;
  position: absolute;
  top: 32px;
}

.website-layer .darkeye-frame-22 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 32px;
  padding: 2.86px;
  position: absolute;
  top: 3px;
}

.website-layer .darkeye-frame-23 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 0;
  padding: 2.86px;
  position: absolute;
  top: 214px;
}

.website-layer .darkeye-frame-24 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 32px;
  padding: 2.86px;
  position: absolute;
  top: 426px;
}

.website-layer .darkeye-frame-25 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 289px;
  padding: 2.86px;
  position: absolute;
  top: 391px;
}

.website-layer .darkeye-frame-26 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 539px;
  padding: 2.86px;
  position: absolute;
  top: 423px;
}

.website-layer .darkeye-frame-27 {
  align-items: center;
  background-color: #0f1a1b;
  border-radius: 1.15px;
  display: inline-flex;
  gap: 2.86px;
  left: 586px;
  padding: 2.86px;
  position: absolute;
  top: 214px;
}

.website-layer .darkeye-title-3 {
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px solid;
  border-color: var(--variable-collection-primary-light);
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 24px;
  position: relative;
}

.website-layer .darkeye-line-12 {
  height: 5px;
  left: 76px;
  position: absolute;
  top: 91px;
  width: 116px;
}

.website-layer .darkeye-retained-customers {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.website-layer .darkeye-testimonial-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 52px;
  max-width: 100%;
  padding: 90px 12vw;
  position: relative;
  /* width: 100%; */
}

.website-layer .darkeye-title-4 {
  height: 92px;
  position: relative;
  width: 400px;
}

.website-layer .darkeye-overlap-group-8 {
  height: 92px;
  position: relative;
  width: 398px;
}

.website-layer .darkeye-line-13 {
  height: 7px;
  left: 251px;
  position: absolute;
  top: 29px;
  width: 135px;
}

.website-layer .darkeye-testimonial-wrap {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  margin-right: -30.00px;
  position: relative;
  width: 80vw;
}

.website-layer .darkeye-testimonial-card {
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px solid;
  border-color: var(--variable-collection-primary-light);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  margin-left: -133.50px;
  padding: 32px;
  position: relative;
}

.website-layer .darkeye-comprehensive-2 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.54px;
  line-height: 23.2px;
  margin-top: -1.00px;
  position: relative;
  width: 387px;
}

.website-layer .darkeye-frame-28 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.website-layer .darkeye-IMG-wa {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 28.92px;
}

.website-layer .darkeye-frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.website-layer .darkeye-text-wrapper-38 {
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.36px;
  line-height: 15.5px;
  opacity: 0.8;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-testimonial-card-2 {
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px solid;
  border-color: var(--variable-collection-primary-light);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  padding: 32px;
  position: relative;
}

.website-layer .darkeye-IMG-2 {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 58.42px;
}

.website-layer .darkeye-testimonial-card-3 {
  align-items: flex-start;
  background-color: var(--primary-light);
  border: 1px solid;
  border-color: var(--variable-collection-primary-light);
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33px;
  margin-right: -133.50px;
  padding: 32px;
  position: relative;
}

.website-layer .darkeye-team-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  padding: 90px 0px;
  position: relative;
  width: 100%;
}

.website-layer .darkeye-text-wrapper-39 {
  color: #0f1a1b;
  font-family: var(--h2-style-font-family);
  font-size: var(--h2-style-font-size);
  font-style: var(--h2-style-font-style);
  font-weight: var(--h2-style-font-weight);
  letter-spacing: var(--h2-style-letter-spacing);
  line-height: var(--h2-style-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.website-layer .darkeye-cards {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  position: relative;
  width: 933px;
}

.website-layer .darkeye-card-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 279px;
}

.website-layer .darkeye-image {
  height: 156px;
  object-fit: cover;
  position: relative;
  width: 163px;
}

.website-layer .darkeye-frame-30 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 243px;
}

.website-layer .darkeye-frame-31 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.website-layer .darkeye-frame-32 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.website-layer .darkeye-CTA-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  background-color: #0f1a1b;
  justify-content: space-around;
  box-sizing: border-box;
  align-items: center;
  padding: 10px 10vw;
}

.website-layer .darkeye-networkLinesWrapper {
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: flex-end;
}

.website-layer .darkeye-networkLines {
  max-width: 100px;
}

.website-layer .darkeye-networkLinesWrapper1 {
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: flex-start;
}

.website-layer .darkeye-networkLines1 {
  max-width: 100px;
  transform: rotate(180deg);
}

.website-layer .darkeye-header-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.website-layer .darkeye-text-wrapper-40 {
  color: var(--primary-light);
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 1.08px;
  line-height: 46.4px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.website-layer .darkeye-footer {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-light);
  display: flex;
  height: 519px;
  justify-content: space-between;
  padding: 90px 10vw 30px 10vw;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.website-layer .darkeye-frame-33 {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 40vh;
  justify-content: space-between;
  position: relative;
  width: 100vw;
}

.website-layer .darkeye-brand-logo-dk {
  background-size: 100% 100%;
  position: relative;
}

.website-layer .darkeye-frame-34 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 35vh;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.website-layer .darkeye-social-icon {
  height: 25px;
  position: relative;
  width: 180px;
  display: flex;
  justify-content: space-evenly;
}

.website-layer .darkeye-youtube {
  height: 24px;
  left: 190px;
  position: absolute;
  top: 0;
  width: 24px;
}



.website-layer .darkeye-twitter {
  height: 24px;
  left: 143px;
  position: absolute;
  top: 0;
  width: 24px;
}


.website-layer .darkeye-linkedin {
  height: 24px;
  left: 102px;
  position: absolute;
  top: 0;
  width: 24px;
}

.website-layer .darkeye-privacy-policy-terms {
  align-self: flex-end;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  opacity: 0.8;
  position: relative;
}

.website-layer .darkeye-rights-reserved {
  align-self: flex-end;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 20.6px;
  opacity: 0.8;
  margin-top: 30px;
  position: relative;
}

.website-layer .darkeye-flinks {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 64px;
  position: relative;
}

.website-layer .darkeye-frame-35 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
  width: max-content;
}

.website-layer .darkeye-text-wrapper-45 {
  align-self: stretch;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.66px;
  line-height: 28.4px;
  margin-top: -1.00px;
  position: relative;
}

.website-layer .darkeye-frame-36 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  position: relative;
  width: max-content;
}

.website-layer .darkeye-flink-options {
  align-self: stretch;
  color: #0f1a1b;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.60px;
  line-height: 25.8px;
  margin-top: -1.00px;
  opacity: 0.8;
  position: relative;
}
